var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VxDialogView',{attrs:{"retain-focus":false,"v-size":'large',"h-size":'medium',"error-toolbar":_vm.checkinRequest &&
    _vm.checkinRequest.customer &&
    _vm.checkinRequest.customer.flagged},scopedSlots:_vm._u([(!_vm.isSmallScreen && _vm.checkinRequest)?{key:"actions",fn:function(){return [_c('CheckinButton',{attrs:{"color":"primary","checkin-request":_vm.checkinRequest,"disabled":_vm.checkInDisabled}})]},proxy:true}:null,(_vm.isSmallScreen && _vm.checkinRequest)?{key:"large-actions",fn:function(){return [_c('CheckinButton',{attrs:{"color":"primary","checkin-request":_vm.checkinRequest,"disabled":_vm.checkInDisabled}})]},proxy:true}:null,{key:"toolbar-title",fn:function(){return [_c('BuyDetailToolbarTitle',_vm._b({on:{"viewCustomer":_vm.handleViewCustomer,"reprintSlips":() => {},"edit":_vm.handleEdit}},'BuyDetailToolbarTitle',{ ..._vm.checkinRequest, reprintDisabled: true },false))]},proxy:true},{key:"toolbar-extension",fn:function(){return [_c('BuyDetailToolbarExtension',{attrs:{"status":_vm.checkinStatus,"tab":_vm.tab},on:{"changeTab":_vm.changeTab}})]},proxy:true}],null,true)},[[(_vm.shouldShowDrsInitialsWarning)?_c('v-banner',{attrs:{"sticky":""}},[_c('v-avatar',{attrs:{"slot":"icon","size":"24px"},slot:"icon"},[_c('v-icon',{attrs:{"color":"error"},domProps:{"textContent":_vm._s(`$alert`)}})],1),[_vm._v(" This employee cannot check-in buys because their DRS initials are missing. Please add DRS initials, or have another employee log in to finish checking in ")]],2):_vm._e(),(_vm.checkinRequest)?_c('v-tabs-items',{staticClass:"fill-height",attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"fill-height",attrs:{"value":"buy"}},[(_vm.checkinRequest && _vm.store)?_c('BuyDialogCustomerForm',_vm._b({on:{"loading":(v) => _vm.$emit('loading', v),"update:field":_vm.updateCustomerField,"update:idField":_vm.updateIdInfo,"update:idVerifyField":_vm.verifyId}},'BuyDialogCustomerForm',{
            ..._vm.checkinRequest,
            buyId: _vm.checkinRequest.id,
            licenseVerified: !!_vm.checkinRequest.licenseVerifiedAt,

            idVerifyErrors: _vm.idVerifyErrors,
            idVerifyNumber: _vm.idVerifyNumber,
            idVerifyLoading: _vm.idVerifyLoading,
            idNumber: _vm.idNumber,
            idState: _vm.idState,
            idDataUpdateLoading: _vm.idDataUpdateLoading,
            store: _vm.store,
          },false)):_vm._e(),(_vm.checkinRequest)?_c('BuyDialogMetaForm',_vm._b({staticClass:"mt-sm-6 mt-3",on:{"loading":(v) => _vm.$emit('loading', v),"update:field":_vm.updateBuyField}},'BuyDialogMetaForm',{
            ..._vm.checkinRequest,
          },false)):_vm._e()],1),_c('v-tab-item',{staticClass:"fill-height",attrs:{"value":"message"}},[(_vm.checkinRequest)?_c('BuyDialogMessaging',{attrs:{"customer-id":_vm.checkinRequest.customerId || undefined}}):_vm._e()],1)],1):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }