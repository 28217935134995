<template>
  <EmployeeAction v-slot="{ handleAction }">
    <v-btn
      text
      class="checkinRequest-dialog__action-button--checkin elevation-0"
      :color="color"
      v-bind="$attrs"
      :disabled="disabled"
      :loading="loading"
      @click="handleAction(handleClick)"
      v-text="buttonText"
    />
  </EmployeeAction>
</template>

<script>
import gql from "graphql-tag";
import {
  injectActiveEmployee,
  hasActiveEmployeeIdAccess,
} from "@/mixins/employee";
import { hasSnackbarAccess } from "@/mixins/ui";
import { buildCheckinPayload } from "@/utils/buy";

import { formatISO } from "date-fns";
import { CREATE_BUY_FROM_CHECKIN_REQUEST_V1 } from "../graphql";
import EmployeeAction from "@/components/employees/EmployeeAction.js";

const GET_BUY = gql`
  query GET_BUY($id: ID!) {
    buy(id: $id) {
      id
      transId
      checkedInAt
      containerNum
      containerDescription
      customerId
      status
      sorterId
      signatureUrl
      customer {
        id
        drsCustomerCode
        firstName
        lastName
        phoneNumber
        email
        loyalty
        fullName
        flagged
        flagReason
      }
    }
  }
`;

export default {
  components: { EmployeeAction },
  mixins: [injectActiveEmployee, hasSnackbarAccess, hasActiveEmployeeIdAccess],
  inheritAttrs: false,
  inject: ["hideDialog"],
  props: {
    checkinRequest: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    color: {
      type: String,
      default: "accent",
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    buttonText() {
      return this.checkinRequest?.status === "buy_created"
        ? "Retry check in"
        : "Check in";
    },
  },
  methods: {
    async createBuyFromCheckinRequest(checkinRequestId) {
      const variables = {
        input: {
          checkinRequestId: checkinRequestId,
        },
      };

      return this.$apollo
        .mutate({
          mutation: CREATE_BUY_FROM_CHECKIN_REQUEST_V1,
          variables,
        })
        .catch((error) => {
          // TODo -add handle error
          if (!this.handleError("Error creating buy", error)) {
            throw error;
          }
        });
    },
    async renderDrsCheckInErrorDialog() {
      await this.$raiError.blocking({
        title: "Error with buy check-in.",
        message:
          "Please close and reopen ResaleAI and try again. If issues persist, send a live chat for help.",
      });
    },
    async handleClick() {
      this.loading = true;
      let checkInSuccess = true;

      try {
        if (this.checkinRequest?.status === "buy_created") {
          await this.retryCheckIn();
        } else {
          checkInSuccess = await this.checkIn();
        }
      } catch (error) {
        console.error(error);
        this.hideDialog();
        return this.showSnackbar({
          text: `An error occurred while checking in this buy. ${error}`,
        });
      } finally {
        this.loading = false;
      }

      if (checkInSuccess) {
        this.showSnackbar({
          text: `Buy checked in successfully!`,
        });
        this.hideDialog();
      }
    },
    async checkInBuyDrs(newBuy) {
      // why would we be setting this?  The mutation should handle this, not the frontend
      if (!newBuy.checkedInAt) {
        // Set `checked_in_at`
        this.$set(newBuy, "checkedInAt", formatISO(new Date()));
      }

      // Set `checked_in_by_employee_code`
      if (!newBuy.checkedInByEmployeeCode) {
        this.$set(
          newBuy,
          "checkedInByEmployeeCode",
          this.activeEmployee.drsEmployeeCode
        );
      }
      // Build the json payloads
      const { buy, customer, loyalty } = buildCheckinPayload(newBuy);

      try {
        // Call the extension to try an insert the buy/customer
        const result = await this.$raiPos.checkIn(buy, customer, loyalty);

        if (!result) {
          await this.renderDrsCheckInErrorDialog();

          throw new Error(
            "An error occurred in the RAI POS extension while checking in this buy"
          );
        }
      } catch (e) {
        await this.renderDrsCheckInErrorDialog();

        throw e;
      }

      // raiPos doesn't tell the backend who checked in the buy, so we do
      // that here.
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation BUY_SET_CHECKIN_EMPLOYEE($input: UpdateBuyInputObject!) {
              updateBuy(input: $input) {
                buy {
                  id
                  checkedInByEmployeeId
                  checkedInAt
                  status
                }
                errors
              }
            }
          `,
          variables: {
            input: {
              id: newBuy.id,
              checkedInByEmployeeId: this.activeEmployeeId,
            },
          },
        });
      } catch (e) {
        console.error(e);
        throw new Error("Invalid employee.");
      }
    },
    async retryCheckIn() {
      const buyId = this.checkinRequest.buyId;
      let buy;
      try {
        const res = await this.$apollo.query({
          query: GET_BUY,
          variables: { id: buyId },
        });
        buy = res.data.buy;
      } catch (e) {
        console.error(e);
        throw new Error("Failed to fetch buy for retry");
      }

      if (!buy) {
        console.error("No buy found for retry, buy id: ", buyId);
        throw new Error("Failed to fetch buy for retry");
      }

      await this.checkInBuyDrs(buy);
    },
    // Create the RAI and DRS buy
    // Massages the buy data a bit, then calls RaiExt.checkIn()
    // Returns true if successful, false if not
    async checkIn() {
      if (!this.activeEmployee?.drsEmployeeCode) {
        // We need to check if the active employee has no drs code, as only after entering pin we know which user does checkin
        // If we've made it this far, show a success message
        this.showSnackbar({
          text: `Current employee does not have a drs code`,
        });

        return false;
      }

      // ---- ^Guards
      if (!this.checkinRequest) {
        return false;
      }

      if (!this.$raiPos) {
        this.showSnackbar({
          text: "Buys can only be checked in on a POS register running ResaleAI POS",
        });
        return false;
      }
      // ---- $Guards

      const createdBuyResponse = await this.createBuyFromCheckinRequest(
        this.checkinRequest.id
      );
      const createdBuy = createdBuyResponse.data.checkinRequestCreateBuyV1.buy;

      await this.checkInBuyDrs(createdBuy);

      // If we've made it this far, the check in was successful
      return true;
    },
  },
};
</script>
